<template>
  <div class="body">
    <div class="margin-left30 margin-top20 positionRelative" style="width: var(--690);height:var(--260);">
      <img style="width: 100%;height:100%"
           src="https://xworld1.oss-cn-beijing.aliyuncs.com/xworld_images/14bdab92-255e-4da2-8abe-6926a24a5ce6.png"/>
      <div class="positionAbsolute flex_able flex-directionColumn"
           style="top:var(--0);right:0;left:var(--0);width:var(--690);">
        <div class="flex_able" style="width:100%;height: var(--166);justify-content: start;align-items: start">
          <div class="flex-directionColumn flex_able margin-top40 margin-left30" style="align-items: start">
          <span class="font-size28 font-colorFFFFFF line-height40">
          总{{ X_VALUE }}:
        </span>
            <span class="font-size48 font-colorFFFFFF line-height66 font-weight600 oneLine margin-top6">
          {{ EncryptUtils.formatDecimal(balanceUsed) }}
        </span>
          </div>
          <div v-if="false" @click="recharge"
               class="flex_center font-size28 font-color026AFC line-height40 positionAbsolute"
               style="width: var(--144);height: var(--60);background: #FFFFFF;border-radius: var(--30);right: var(--30);top: var(--40);">
            兑换金钻
          </div>
        </div>
      </div>
    </div>
    <div v-if="false" class="dashBoard">
      <div class="dashBoardItem flex_able">
        <span
            class="font-size30 font-color333333 font-weightBold line-height42 margin-left30">上月累计可用能量总数：</span>
        <span style="flex: 1"
              class="oneLine font-size53 font-color026AFC font-weightBold">{{ totalInBalanceLastMonth }}</span>
      </div>

      <div class="dashBoardItem flex_able">
        <span
            class="font-size30 font-color333333 font-weightBold line-height42 margin-left30">本月累计可用能量总数：</span>
        <span style="flex: 1"
              class="oneLine font-size53 font-color026AFC font-weightBold">{{ totalInBalanceNowMonth }}</span>
      </div>
    </div>
    <!-- ----------------------------第三部分 影响力记录-------------------------------------------- -->
    <div v-for="(item, index) in tabList" :key="index">
      <div @click="choiceDate" class="flex_able"
           style="background: #ffffff;height: var(--80);padding-left: var(--30);padding-right: var(--30);">
        <div class="font-size32 font-color1B1B1B" style="flex: 1;font-weight: bold;">
          全部
        </div>
        <title-arrow :text="selectDate"/>
      </div>
      <div style="height: var(--1);background: #DDDDDD;border-radius: var(--4);"></div>
      <van-list v-model=item.loading :immediate-check="false" :finished=item.finished
                :finished-text=item.finishedText
                @load="getTabListContent(item,index)" v-if="item.contentList.length > 0">
        <div v-for="(item, index) in item.contentList" :key="index"
             style="height: var(--138);">
          <div class="flex_center"
               style="justify-content: center;height: var(--138);">
            <div
                style="margin-left: var(--30);margin-right: var(--30);flex:1;font-size: var(--30);
                  font-weight: 400;color: #1B1B1B;">
              <div class="oneLine" style="line-height: var(--42)">
                {{ item.flowTypeDetail }}
              </div>
              <div
                  style="font-weight: 400;margin-top: var(--6);font-size: var(--24);color: #999999;line-height: var(--34)">
                {{ item.flowTypeExplain }}
              </div>
            </div>
            <div
                style="margin-right: var(--30);display:flex;flex-direction:column;align-items:end;font-size: var(--28);font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: #1B1B1B;">
              <div v-if="item.rollType == 1" class="oneLine"
                   style="max-width:var(--350);width: 100%;line-height: var(--40);text-align: right;color:#026AFC;">
                +{{ item.amount }}
              </div>
              <div v-if="item.rollType != 1" class="oneLine"
                   style="max-width:var(--350);width: 100%;line-height: var(--40);text-align: right;color: #FF1F1F">
                -{{ item.amount }}
              </div>
              <div class="oneLine"
                   style="max-width:var(--350);width: 100%;text-align: right;font-weight: 400;font-size: var(--24);color: #999999;height: var(--34);margin-top: var(--10);">
                {{ item.ctime.replace("T", " ") }}
              </div>
            </div>
          </div>

        </div>
      </van-list>

      <div
          style="text-align: center;width: 94.8vw;border-radius: 1.3vw;background: #fff;position: relative;line-height: 19.7vw;color: #000;font-size: 4vw;font-weight: bold;margin: 2.8vw auto auto auto;"
          v-if="item.contentList.length === 0 && index === 0">暂无可用明细
      </div>
      <div
          style="text-align: center;width: 94.8vw;border-radius: 1.3vw;background: #fff;position: relative;line-height: 19.7vw;color: #000;font-size: 4vw;font-weight: bold;margin: 2.8vw auto auto auto;"
          v-if="item.contentList.length === 0 && index === 1">暂无冻结明细
      </div>

    </div>

    <!-- 时间选择器 -->
    <date-choice-dialog type="year-month" ref="controlDateDialog" @handleChange="dateCallback"
                        @handleChangeSelectDate="selectDateCallback"/>
  </div>
</template>

<script>

import TitleArrow from "@/components/TitleArrow.vue";
import {Api} from "@/utils/net/Api";
import {Toast} from "vant";
import DateChoiceDialog from "@/components/DateChoiceDialog.vue";
import {Constants} from "@/utils/constants";
import EncryptUtils from "../../../utils/EncryptUtils";

export default {
  computed: {
    EncryptUtils() {
      return EncryptUtils
    }
  },
  components: {DateChoiceDialog, TitleArrow},
  data() {
    return {
      X_VALUE: Constants.R_VALUE,
      totalBalance: "",//总影响力
      balanceUsed: "",//可用影响力
      totalInBalanceLastMonth: "",//上月累计可用能量总数：
      totalInBalanceNowMonth: "",//本月累计可用能量总数：
      selectDate: "",//显示选中的时间
      monthNum: "",//给接口的时间
      tabList: [],
      imgUrl: "",
      balance: "0",
      coinName: "",
      assetType: "",
    }
  },
  created() {
    //开始5
    try {
      //设置标题
      document.title = Constants.R_VALUE + "详情"
      window.Android.setTitleStyle(JSON.stringify({
        titleBarBackgroundColor: '#FFFFFF',
        textColor: '#1B1B1B',
        showTitleBar: "true"
      }));
      window.Android.setStatusBarStyle(JSON.stringify({
        isDark: "true",
        statusBarBgColor: "#ffffff",
        showStatusBar: "true"
      }));
    } catch (error) {
      console.log("常规错误:" + error)
    }
    this.queryPowerAccount()
    this.queryPageConfiguration()
    window.handleScanActivityResult = this.handleScanActivityResult
  },
  methods: {
    goToDetail() {
      this.$router.push({
        path: '/energyExchange',
      });
    },
    handleScanActivityResult(result) {
      console.log(result)
      if (result.includes(Constants.CODE_TAG)) {
        let splitList = result.split("-");
        let userNum = splitList[0]
        if (userNum.length > 0) {
          //跳转到转赠页面
          window.location.href = Constants.goToAccountTradeActivity + "?userNum=" + userNum
        } else {
          Toast("用户昵称获取失败")
        }
      }
    },
    recharge() {
      this.goToDetail()
      // window.location.href = Constants.goToRechargeInfluence
    },
    queryPowerAccount() {
      let params = {
        assetType: Constants.R_COIN_COIN_X,
      }
      let that = this;
      Api.queryPowerAccount(params).then((result) => {
        if (result.code === 100) {
          that.balanceUsed = result.data?.accountVo?.balance
          that.totalInBalanceLastMonth = result.data?.totalInBalanceLastMonth
          that.totalInBalanceNowMonth = result.data?.totalInBalanceNowMonth
        } else {
          Toast(result.msg);
        }
      })
    },
    getTabList() {
      return [
        {
          title: "可用明细",
          assetType: Constants.R_COIN_COIN_X,
          finished: false,
          pageNo: 1,
          loading: false,
          finishedText: "没有更多了",
          pageSize: 10,
          contentList: [],
        }
      ]
    },
    //组件回调 经过各种判断后返回加密后文字
    selectDateCallback(value) {
      console.log(value);
      this.selectDate = value
    },
    //时间选择器 处理后给接口的日期格式
    dateCallback(value) {
      console.log(value);
      this.monthNum = value
      this.tabList = this.getTabList()
      this.tabList.forEach((item, index) => {
        this.getTabListContent(item, index)
      })
    },
    choiceDate() {
      //显示日期
      this.$refs.controlDateDialog.controlDialog(true);
    },
    getTabListContent(item, index) {
      let tab = item
      if (tab.finished) {
        return;
      }
      let data = {
        pageNo: tab.pageNo,
        pageSize: tab.pageSize,
        assetType: tab.assetType,
        monthNum: this.monthNum
      }
      let apiObj;
      if (index == 0) {
        apiObj = Api.queryPowerRecord(data)
      } else {
        apiObj = Api.queryPowerRecord(data)
      }
      if (apiObj) {
        apiObj.then(res => {
          if (res.code === 100) {
            let pageInfo;
            if (index == 0) {
              pageInfo = res.data.pageInfo
            } else {
              pageInfo = res.data.pageInfo
            }
            let resData = pageInfo,
                list = resData.list;
            if (resData.isLastPage) { // 没有更多了
              tab.finished = true;
            }
            if (index == 0) {
              tab.contentList = tab.contentList.concat(this.handleList(list));
            } else {
              tab.contentList = tab.contentList.concat(this.handleList2(list));
            }
            tab.pageNo = resData.nextPage;
            tab.loading = false;
          } else {
            Toast(res.msg);
          }
        })
      }
    },
    handleList(list) {
      // list.forEach(item => {
      //
      // })
      return list;
    },
    handleList2(list) {
      // list.forEach(item => {
      //   item['redPackName'] = item.sendUserName
      //   item['time'] = item.getTime.replace("T"," ")
      //   item['alreadyGrabDetail'] = " "
      // })
      return list;
    },
  },
}
</script>

<style scoped>
.body {
  background-color: #ffffff;
  width: 100vw;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.topVitality {
  width: var(--344);
  display: flex;
  flex-direction: row;
}

.topVitalityTitle {
  font-size: var(--28);
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  line-height: var(--40);
}

::v-deep .textClass {
  color: #999999;
  font-size: var(--25);
  line-height: var(--34);
}

::v-deep .textImg {
  margin-left: var(--8);
  width: var(--24);
  height: var(--14);
  content: url("https://xworld1.oss-cn-beijing.aliyuncs.com/xworld_images/47c803e7-4ab3-46bd-9397-b317fa2fe4ae.png");
}

.grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.item {
  margin-top: var(--40);
  margin-bottom: var(--40);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.item-img {
  width: var(--108);
  height: var(--108);
  object-fit: cover;
}

.item-title {
  margin-top: var(--20);
  font-size: var(--28);
  color: #333333;
}

.dashBoard {
  padding-bottom: var(--26);
  margin: var(--28) auto;
  width: var(--690);
  border-radius: var(--20);
  border: var(--2) solid #F0F0F0;
}

.dashBoardItem {
  margin-left: auto;
  margin-right: auto;
  margin-top: var(--24);
  width: var(--630);
  height: var(--94);
  background: #FAFAFA;
  border-radius: var(--24);
}
</style>
