<!-- 标题右侧带箭头 -->
<template>
  <div>
    <div class="flex_able">
      <span :style="{'color': textColor,'font-size':textSize,'line-height':lineHeight}" class="textClass">{{ text }}</span>
      <img v-if="!hideImage && imageUrl.length == 0" class="textImg" src="" alt=""
           :style="{'width': imageWidth,'height':imageHeight,'margin-left':marginLeft}"/>
      <img v-if="!hideImage && imageUrl.length > 0" class="textImg" :src="imageUrl" alt=""
           :style="{'width': imageWidth,'height':imageHeight,'margin-left':marginLeft}"/>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    text: {},
    hideImage: {},
    marginLeft: {},
    textColor: {},
    textSize: {},
    imageWidth: {},
    imageHeight: {},
    lineHeight: {},
    imageUrl: {
      type: String,
      default: ""
    },
  },
  data() {
    return {
    }
  },
  methods: {}
}
</script>

<style scoped>
.textClass {
  font-size: var(--30);
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #FE2B54;
}

.textImg {
  width: var(--26);
  height: var(--14);
  margin-left: var(--8);
}
</style>
