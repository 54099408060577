<template>
  <div>
    <!-- 时间选择器 -->
    <van-popup v-model="showPop" position="bottom" round :style="{ height: '30%' ,width:'100%'}"
               close-on-click-overlay:true overlay:true>
      <van-datetime-picker v-model="currentDate" :type="type" title="选择时间" :min-date="minDate"
                           :max-date="maxData" @cancel=controlDialog(false) @confirm=onChangDate
                           :formatter="formatter"/>
    </van-popup>
  </div>
</template>
<script>

export default {
  props: {
    //year-month
    //date
    type: {},
  },
  data() {
    return {
      currentDate: new Date(),
      maxData: new Date(),
      minDate: new Date(2023, 6, 1),
      showPop: false,
      yearMonth: "year-month",
      yearMonthDay: "date",
    }
  },
  created() {
    //刚开始调用给外面显示时间
    let selectDate = this.formatDate(new Date())
    this.$emit('handleChangeSelectDate', selectDate)
    //接口时间
    let paramsDate = this.formatDateParams(new Date()) //接口的时间格式
    this.$emit('handleChange', paramsDate)
  },
  methods: {
    controlDialog(value) {
      let that = this;
      that.showPop = value
    },
    // 时间选择器-------------------------------
    formatter(type, val) {
      if (type === 'year') {
        return `${val}年`;
      } else if (type === 'month') {
        return `${val}月`;
      } else if (type === 'day') {
        return `${val}日`;
      }
      return val;
    },
    onChangDate(value) {
      console.log("时间" + value)
      //显示时间
      let selectDate = this.formatDate(value) //显示的时间格式
      console.log("当前时间--" + selectDate)
      //接口时间
      let paramsDate = this.formatDateParams(value) //接口的时间格式
      this.$emit('handleChangeSelectDate', selectDate) // 触发父组件中handleChange事件并传参Jack
      this.$emit('handleChange', paramsDate) // 触发父组件中handleChange事件并传参Jack
      this.controlDialog(false)
    },
    formatDate(value) {//显示的时间格式
      //参数和显示的格式不一致 ，接口接收格式：202303    显示格式：2023-3
      // let month = value.getMonth() >= 9 ? value.getMonth() + 1 : (value.getMonth() + 1); //2023-3
      if (this.type == this.yearMonth) {
        return value.getFullYear() + '-' + (value.getMonth() + 1);   //显示格式
      } else {
        return value.getFullYear() + '-' + (value.getMonth() + 1) + '-' + value.getDate();   //显示格式
      }
    },
    formatDateParams(value) {//接口的时间格式
      //参数和显示的格式不一致 ，接口接收格式：202303    显示格式：2023-3
      let day = value.getDate() >= 10 ? value.getDate() : "0" + value.getDate(); //202303
      let monthParams = value.getMonth() >= 9 ? value.getMonth() + 1 : "0" + (value.getMonth() + 1); //202303
      if (this.type == this.yearMonth) {
        //接口格式
        return value.getFullYear() + "" + monthParams
      } else {
        //接口格式
        return value.getFullYear() + "" + monthParams + day
      }
    },
    // 时间选择器-------------------------------
  },
}
</script>
<style scoped>

</style>
